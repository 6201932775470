import {
  SessionState,
  StreamContextResponse,
} from '@bridge/types/SoloRTCChannelTypes';
import { SessionProtocols } from '@bridge/types/SessionTypes';
import { CoreFactory } from '@bridge/factory/CoreFactory';
import {
  sessionManager,
  useSessionStore,
  credentialsSource,
} from '@stores/session';
import { useCallback, useEffect, useState } from 'react';
import {
  IAllocateResourceSuccessResponse,
  WsBrokerLoginType,
} from '@core/wsbroker/types';
import { MetricResult, Operation } from '@bridge/types/MetricTypes';
import { PreSessionFactory } from '@bridge/factory/PreSessionFactory';

const rtcChannel = CoreFactory.getRTCChannel();
const metrics = CoreFactory.getMetrics();
const logger = CoreFactory.getLogger();
const streamInputGenerator = PreSessionFactory.getNativeStreamInputGenerator();

// ToDo: Refactor this code to a class instead of a hook for simpler error handling
export const useNativeStreaming = (
  protocol: SessionProtocols,
  domainName: string,
  allocatedResource?: IAllocateResourceSuccessResponse
) => {
  const setSession = useSessionStore((state) => state.setSession);

  const [error, setError] = useState<any>();
  const [isStreamContextRequested, setIsStreamContextRequested] =
    useState(false);

  const [streamContextReceived, setStreamContextReceived] = useState(false);
  const [streamContextResponse, setStreamContextResponse] =
    useState<StreamContextResponse>();

  const [isStreamRequested, setIsStreamRequested] = useState(false);
  const [isStreamSuccess, setIsStreamSuccess] = useState(false);

  // callback function to handle StreamContext RTC response from solo
  const rtcMessageStreamContextCallback = useCallback(
    (payload: StreamContextResponse) => {
      setSession({ sessionState: SessionState.STREAM_CONTEXT_RECEIVED });
      metrics.emit(Operation.StreamContextRequest, MetricResult.Success);
      setStreamContextReceived(true);
      setStreamContextResponse(payload);
    },
    [setSession, setStreamContextReceived]
  );

  const rtcMessageStreamCallback = useCallback((payload: any) => {
    setIsStreamSuccess(true);
  }, []);

  const requestStreamSessionContext = () => {
    if (!rtcChannel) return;
    try {
      const getAuthBlob = sessionManager.get('authBlob');
      const SessionAuthBlob =
        credentialsSource === 'CLIENT' ? getAuthBlob : undefined;
      logger.info(
        `Initiating the Stream Session Context for Broker Protocol ${
          credentialsSource === 'CLIENT' ? 'V2' : 'V1'
        } workflow`
      );

      rtcChannel.requestStreamSessionContext(
        protocol,
        domainName,
        allocatedResource,
        SessionAuthBlob,
        rtcMessageStreamContextCallback
      );
    } catch (e: any) {
      logger.error(
        `Encountered error while requesting streaming context ${e?.message}`
      );
      setError(e);
    }
  };

  const shouldStreamRequestBeSkipped = (
    allocatedResource?: IAllocateResourceSuccessResponse
  ): boolean => {
    const loginType = allocatedResource?.ActionResult.ResultValues?.loginType;
    const protocolType =
      allocatedResource?.ActionResult.ResultValues?.sessionProtocol;
    const getServiceCapability = sessionManager.get('primaryAuthMethod');
    const serviceCapabilityValue =
      getServiceCapability?.ServiceCapabilities?.CredSource;

    // Skip for WSP protocol in PWD ReArch workflow
    return (
      loginType === WsBrokerLoginType.FixedAuthBlob ||
      loginType === WsBrokerLoginType.VirtualSmartCardCertificate ||
      (serviceCapabilityValue === 'CLIENT' &&
        (protocolType === SessionProtocols.MAXIBON ||
          protocolType === SessionProtocols.WSP ||
          protocolType === SessionProtocols.DCV))
    );
  };

  const generateStreamInputsAndRequestStreaming = () => {
    if (!rtcChannel) return;

    try {
      const streamInputs =
        streamInputGenerator.generateNativeClientStreamInputs(
          protocol,
          allocatedResource,
          streamContextResponse as StreamContextResponse
        );
      logger.info('Requesting streaming for workspace');

      rtcChannel.initializeStreaming(
        protocol,
        streamInputs,
        rtcMessageStreamCallback
      );
    } catch (e: any) {
      logger.error(
        `Encountered error while requesting streaming start ${e?.message}`
      );
      setError(e);
    }
  };

  useEffect(() => {
    if (shouldStreamRequestBeSkipped(allocatedResource)) {
      logger.info('Skipping stream context generation');

      /*
        ToDo: Ideally would need to use a different boolean isStreamContextSkipped here, but managing different
        calls in this way in hooks is not recommended. This code needs to be moved to a class instead. Will be refactoring
        this code as part of Port 443 support by 07/31. Will keep this code as it is for now. 
      */
      setIsStreamContextRequested(true);
      rtcMessageStreamContextCallback({
        StreamAttributes: {},
      });
    } else if (!isStreamContextRequested) {
      logger.info('Proceeding to stream context generation');
      requestStreamSessionContext();
      setIsStreamContextRequested(true);
    }
  }, []);

  useEffect(() => {
    if (streamContextReceived && !isStreamRequested) {
      generateStreamInputsAndRequestStreaming();
      setIsStreamRequested(true);
    }
  }, [streamContextReceived, isStreamRequested, setIsStreamRequested]);

  return { isStreamSuccess, error };
};
